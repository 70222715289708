import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { LeadLineDataModel } from 'src/app-core/data-model/leadline-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';

@Component({
    selector: 'app-leadline-form-check-dialog',
    templateUrl: './leadline-form-check-dialog.component.html',
    styleUrls: ['./leadline-form-check-dialog.component.scss']
})

export class LeadlineFormCheckDialogComponent implements OnInit {

    commonDataModel = new CommonDataModel();
    leadLineDataModel = new LeadLineDataModel();
    leadLineNavigationModel = _.cloneDeep(this.leadLineDataModel.leadLineNavigationModel);
    paramLeadId: any;
    paramLeadLineId: any;
    paramRequestId: any;
    formTitle: any;
    leadline_mandatory_check: any;
    isFormLoading = false;
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor(
        public commonMethod: CommonMethod,
        private dialogRef: MatDialogRef<LeadlineFormCheckDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data?: any) {
        if (data) {
            this.paramLeadId = data.leadId;
            this.paramLeadLineId = data.leadlineId;
            this.paramRequestId = data.requestId;
            this.formTitle = data.formTitle;
            this.leadline_mandatory_check = data.leadline_sections;
        }
    }

    ngOnInit(): void { }

    mapParamData() {
        return {
            lead_id: this.paramLeadId,
            leadline_id: this.paramLeadLineId,
            request_id: this.paramRequestId
        }
    }

    onNavigateLeadLine(dataItem: any): void {
        if (dataItem?.form_name) {
            const index = _.findIndex(this.leadLineNavigationModel, ['label', dataItem.form_name]);
            if (index !== -1) {
                this.commonMethod.leadLineModuleRedirection(this.mapParamData(), `lead-line/${this.leadLineNavigationModel[index].route}`);
                this.dialogRef.close();
            }
        }
    }

    onSetImage(value: boolean) {
        return value ? 'check-circle-icon' : 'info-circle-icon';
    }

    onClose() {
        this.dialogRef.close({ confirmResult: true });
    }

}
