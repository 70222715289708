import { DefaultResultsPerPage } from "../enums/common-enum"

export class ProfileDataModel {

    public profileFormPayload: any = {
        name: '',
        email: '',
        group: '',
        role: '',
        file: '',
        url: '',
        user_role: {
            name: ''
        },
        notifications: []
    }

    public userSettingsFormPayload: any = {
        hydro: false,
        budget: false,
        cad: false,
        sizing_user: '',
        sizing_user_optional: [],
        pricing_user: '',
        pricing_user_optional: [],
        drawing_user: '',
        drawing_user_optional: []
    }

    public userSettingsFormLoaderModel: any = {
        isFormLoading: false,
        isCallsLoading: false,
        isResponderLoading: false
    }

    public userFormPayload: any = {
        id: '',
        name: '',
        email: '',
        role: '',
        group: '',
        file: null,
        m1_userid: '',
        vendor_user: false,
        isRequestLoading: true,
        user_image: {
            id: '',
            file: '',
            url: ''
        },
        user_permission: {
            hydro: false,
            budget: false,
            cad: false,
            is_doc_manager: false
        },
        is_reporting: false,
        is_six_month_pipeline: false,
        is_one_year_pipeline: false
    }

    public userFormLoaderModel: any = {
        isFormLoading: false,
        isRoleLoading: false,
        isGroupLoading: false
    }

    public searchFormPayload: any = {
        skip_count: 0,
        per_page: DefaultResultsPerPage.value,
        sort_by: '',
        sort_order: '',
        filter_key: '',
        search_key: '',
        filter_value: '',
        pending: true,
        open: true,
        complete: false,
        year: '',
        mode: '',
        value: '',
        person1: '',
        person2: '',
        metric: '',
        usertype: 'all',
        timezone: new Date().getTimezoneOffset().toString()
    }

    public chartDataTypeModel: any = [
        {
            name: 'Sizing',
            Sizing: []
        },
        {
            name: 'Pricing',
            Pricing: []
        },
        {
            name: 'Drawing',
            Drawing: []
        },
        {
            name: 'Issue',
            Issue: []
        }
    ]

    public chartUserListModel: any = {
        Sizing: [],
        Pricing: [],
        Drawing: [],
        Issue: []
    };

    public chartSeriesColorsModel: string[] = [
        '#FF9191',
        '#34B4E5',
        '#EFA71C',
        '#55759A',
    ];

    public timePeriodList: any = [
        {
            value: 'quarterly',
            text: 'Quarterly'
        },
        {
            value: 'yearly',
            text: 'Annually'
        },
        {
            value: 'monthly',
            text: 'Monthly'
        }
    ]

    public requestList: any = [
        {
            value: 'all',
            text: 'All Requested & Assigned'
        },
        {
            value: 'assigned',
            text: 'Assigned To User'
        },
        {
            value: 'created',
            text: 'RSM'
        }
    ]

    public metricsList: any = [
        {
            value: 'issue',
            text: 'Issues'
        },
        {
            value: 'sizing',
            text: 'Sizing'
        },
        {
            value: 'drawing',
            text: 'Drawing'
        },
        {
            value: 'pricing',
            text: 'Pricing'
        }
    ]

    public quarterList: any = [
        {
            value: 'Q1',
            text: 'Quarter 1'
        },
        {
            value: 'Q2',
            text: 'Quarter 2'
        },
        {
            value: 'Q3',
            text: 'Quarter 3'
        },
        {
            value: 'Q4',
            text: 'Quarter 4'
        }
    ]

    public monthList: any = [
        {
            text: 'January',
            value: 'jan'
        },
        {
            text: 'February',
            value: 'feb'
        },
        {
            text: 'March',
            value: 'mar'
        },
        {
            text: 'April',
            value: 'apr'
        },
        {
            text: 'May',
            value: 'may'
        },
        {
            text: 'June',
            value: 'june'
        },
        {
            text: 'July',
            value: 'july'
        },
        {
            text: 'August',
            value: 'aug'
        },
        {
            text: 'September',
            value: 'sep'
        },
        {
            text: 'October',
            value: 'oct'
        },
        {
            text: 'November',
            value: 'nov'
        },
        {
            text: 'December',
            value: 'dec'
        }
    ]

}