import { Component, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import * as _ from 'lodash';
import { Subscription, finalize } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { GridDataModel } from 'src/app-core/data-model/grid-data-model';
import { ConformationDialogEnum, DocumentStatusEnum } from 'src/app-core/enums/common-enum';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { AppComponentBase } from 'src/app/shared/app-component-base';
import { RequestFormComponent } from 'src/app/shared/request-form/request-form.component';
import { environment } from 'src/environments/environment';
import { AddCommentFormComponent } from '../add-comment-form/add-comment-form.component';
import { DrawingDocumentUploadFormComponent } from '../drawing-document-upload-form/drawing-document-upload-form.component';
import { SizingDocumentUploadFormComponent } from '../sizing-document-upload-form/sizing-document-upload-form.component';
import { LeadlineFormCheckDialogComponent } from '../leadline-form-check-dialog/leadline-form-check-dialog.component';

@Component({
    selector: 'app-document-status-list',
    templateUrl: './document-status-list.component.html'
})
export class DocumentStatusListComponent extends AppComponentBase implements OnInit, OnDestroy {

    @ViewChild('kg', { static: true }) private grid: any = GridComponent;
    @ViewChild(TooltipDirective, { static: true }) public tooltipDir!: TooltipDirective;
    componentSubscriptions: Subscription = new Subscription();
    gridDataModel = new GridDataModel();
    commonDataModel = new CommonDataModel();
    gridFilterModel = _.cloneDeep(this.commonDataModel.gridFilterModel);
    gridPayload = _.cloneDeep(this.commonDataModel.gridPayload);
    leadCommonGridColumnModel = _.cloneDeep(this.gridDataModel.leadCommonGridColumnModel);
    permissionDataModel = this.commonDataModel.permissionDataModel;
    gridColumnModel: any[] = [];
    paramLeadId: any;
    paramLeadLineId: any;
    paramRequestId: any;
    loggedUserId: any;
    formType: any;
    gridName: string = '';
    isDisabled = false;
    permissionToEdit = false;
    isFormLoading = false;
    baseUrl = environment.baseUrl;
    gridDataCopy: any[] = [];
    activeUserSourceList: any[] = [];
    selectedIndexValue = 0;
    isDocumentWrapperExpand = false;
    isShowLogsNotification = false;
    selectedDocumentRequestId: any;
    logsNotificationCount = 0;
    commonConstants: typeof CommonConstants = CommonConstants;
    documentStatusEnum: typeof DocumentStatusEnum = DocumentStatusEnum;
    conformationDialogEnum: typeof ConformationDialogEnum = ConformationDialogEnum;

    @Input() set gridColumnList(data: any) {
        if (data) {
            this.gridColumnModel = this.commonMethod.gridColumnMapping(data, this.leadCommonGridColumnModel);
        }
    }

    @Input() set gridNameValue(data: string) {
        this.gridName = data;
        this.gridPayload.grid_name = data;
        switch (data) {
            case this.commonConstants.gridNameConstants.sizing:
                this.formType = this.commonConstants.leadLineConstants.sizing;
                break;
            case this.commonConstants.gridNameConstants.pricing:
                this.formType = this.commonConstants.leadLineConstants.pricing;
                break;
            case this.commonConstants.gridNameConstants.drawing:
                this.formType = this.commonConstants.leadLineConstants.drawing;
                break;
            default:
                break;
        }
        this.permissionToEdit =
            (
                this.formType !== this.commonConstants.leadLineConstants.pricing &&
                this.permissionDataModel.role === this.commonConstants.appRoleConstants.operationMember
            ) || (
                this.formType === this.commonConstants.leadLineConstants.pricing &&
                this.permissionDataModel.role !== this.commonConstants.appRoleConstants.operationMember
            );
    }

    constructor(
        injector: Injector,
        private matDialog: MatDialog,
        private commonMethod: CommonMethod,
        private commonService: CommonService,
        private activeRoute: ActivatedRoute,
        private customToastrService: CustomToastrService) {
        super(injector);
        this.loggedUserId = this.commonMethod.getUserId();
        this.permissionDataModel = this.commonMethod.mappingPermissionModel();
    }

    ngOnInit(): void {
        this.onUpdatUserPermission();
        this.componentSubscriptions.add(this.activeRoute.paramMap.subscribe(params => {
            this.paramLeadId = params.get('leadId');
            this.paramLeadLineId = params.get('leadlineId');
            this.paramRequestId = params.get('requestId');
            this.onCheckLeadLineStatus();
        }));
        this.componentSubscriptions.add(
            this.commonMethod.getConformationDialogValue.subscribe((data: any) => {
                if (data) {
                    this.onRefreshGrid();
                    this.commonMethod.sendLoadFieldCheck(true);
                }
                if (data && this.formType === this.commonConstants.leadLineConstants.pricing) {
                    this.commonMethod.sendLoadLeadLineDetailsRecords(true);
                }
            })
        );
        this.componentSubscriptions.add(
            this.commonMethod.getDeleteConformationValue.subscribe((data: boolean) => {
                if (data) {
                    this.onCancelRequest();
                }
            })
        );
        this.componentSubscriptions.add(
            this.commonMethod.getDueDateDialogValue.subscribe((data: boolean) => {
                if (data) {
                    this.onRefreshGrid();
                }
            })
        );
        this.componentSubscriptions.add(
            this.commonMethod.getReassignUserDialogValue.subscribe((data: boolean) => {
                if (data) {
                    this.onRefreshGrid();
                }
            })
        );
        this.componentSubscriptions.add(
            this.commonMethod.getUploadDocumentDialogValue.subscribe((data: boolean) => {
                if (data) {
                    this.onRefreshGrid();
                }
            })
        );
        this.componentSubscriptions.add(
            this.commonMethod.getLoadDocumentStatusGrid.subscribe((data: boolean) => {
                if (data) {
                    this.onRefreshGrid();
                }
            })
        );
        this.componentSubscriptions.add(
            this.commonMethod.getLoadAllLogsRecords.subscribe((data: boolean) => {
                if (!data) {
                    this.logsNotificationCount = 0;
                    this.isShowLogsNotification = false;
                }
            })
        );
    }

    onCheckLeadLineStatus(): void {
        this.commonService.getLeadLineDetails(this.paramLeadId, this.paramLeadLineId).subscribe(response => {
            if (response.data) {
                this.isDisabled = !response.data?.is_active;
                this.onLoadLogsNotification();
                this.onLoadItems();
            }
        });
    }

    onLoadItems(): void {
        this.kendogridHelper.showLoadingIndicator();
        this.commonService.getGridFilters(this.gridName)
            .subscribe(response => {
                if (response.data) {
                    this.commonMethod.mappingGridFilters(response?.data, this.kendogridHelper, this.gridFilterModel, this.gridColumnModel);
                    _.filter(this.gridColumnModel, (list => {
                        if (this.formType === CommonConstants.leadLineConstants.pricing && list.field === 'comment') {
                            list.hidden = true;
                        }
                    }));
                    if (this.formType === CommonConstants.leadLineConstants.sizing) {
                        _.remove(this.gridColumnModel, { field: 'comment' });
                    }
                    if (this.formType === CommonConstants.leadLineConstants.drawing) {
                        _.remove(this.gridColumnModel, { field: 'comment' });
                    }
                }
                this.onRefreshGrid();
            });
        this.commonService.getActiveUserList().subscribe(response => {
            this.activeUserSourceList = response?.data;
        });
        this.commonMethod.sendLoadAllCommentsValue(this.paramRequestId);
    }

    onLoadLogsNotification(): void {
        const inputData = {
            lead_id: this.paramLeadId,
            leadline_id: this.paramLeadLineId
        }
        this.logsNotificationCount = 0;
        this.commonService.getLogsNotification(inputData).subscribe(response => {
            if (response.data) {
                this.logsNotificationCount = response.data?.unread_notification_count || 0;
                if (this.logsNotificationCount && this.logsNotificationCount !== 0) {
                    this.isShowLogsNotification = true;
                } else {
                    this.isShowLogsNotification = false;
                }
            }
        });
    }

    onRefreshGrid() {
        this.grid.dataStateChange.emit({
            skip: 0,
            take: this.kendogridHelper.defaultRecordsCountPerPage,
            filter: this.kendogridHelper.filter,
            group: this.kendogridHelper.groups,
            sort: this.kendogridHelper.sort
        });
    }

    getGridRecords(event: DataStateChangeEvent) {
        if (event) {
            this.kendogridHelper.dataStateChangeEvent(event);
        }
        this.kendogridHelper.showLoadingIndicator();
        this.commonMethod.gridObjectMapping(this.gridPayload, event);
        this.commonMethod.objectMapping(this.gridFilterModel, this.gridPayload);
        this.commonService.postGridRecords(this.paramLeadId, this.paramLeadLineId, this.formType, this.gridPayload)
            .pipe(finalize(() => this.kendogridHelper.hideLoadingIndicator()))
            .subscribe(response => {
                if (response.data) {
                    _.filter(response.data?.data, (list => {
                        list.isEdit = false;
                        list.model = (list.model ? list.model : list.equip_type);
                        list.price = this.commonMethod.convertCurrency(list.price) || '$0.00';
                    }));
                    this.gridDataCopy = _.cloneDeep(response.data.data);
                    this.kendogridHelper.setGridData(response.data);
                }
            });
    }

    onColumnReorder(event: any): void {
        this.gridFilterModel.column_fields = this.commonMethod.setGridColumnReorder(event, this.gridColumnModel);
        this.updateGridFilters();
    }

    onColumnResize(event: any): void {
        this.commonMethod.setGridColumnResize(event, this.gridColumnModel);
        this.updateGridFilters();
    }

    onChangeColumnOptions(event: any, index: number): void {
        this.gridColumnModel[index].hidden = !event.checked;
        this.gridFilterModel.column_fields = Object.assign([], this.gridColumnModel);
        this.updateGridFilters();
    }

    onSortChange(): void {
        this.updateGridFilters();
    }

    onGroupChange(): void {
        this.updateGridFilters();
    }

    updateGridFilters(): void {
        this.commonMethod.updateGridFilters(this.gridName, this.gridFilterModel, this.gridColumnModel);
    }

    onShowRequestForm(): void {
        this.isFormLoading = true;
        this.commonService.getLeadlineFieldsCheck()
            .pipe(finalize(() => this.isFormLoading = false))
            .subscribe(response => {
                if (response.data.leadline_form) {
                    this.matDialog.open(RequestFormComponent, {
                        position: { right: '0' },
                        panelClass: 'sm-dialog-wrapper',
                        hasBackdrop: false,
                        data: {
                            leadId: this.paramLeadId,
                            leadlineId: this.paramLeadLineId,
                            document_type: this.formType,
                            formTitle: this.gridName
                        }
                    }).afterClosed().subscribe((result: { confirmResult: boolean }) => {
                        if (result?.confirmResult) {
                            this.onRefreshGrid();
                        }
                    });
                } else {
                    this.matDialog.open(LeadlineFormCheckDialogComponent, {
                        panelClass: 'confirm-dialog-wrapper',
                        hasBackdrop: false,
                        data: {
                            leadId: this.paramLeadId,
                            leadlineId: this.paramLeadLineId,
                            requestId: this.paramRequestId,
                            formTitle: this.gridName,
                            leadline_sections: response.data
                        }
                    }).afterClosed().subscribe((result: { confirmResult: boolean }) => {
                        if (result?.confirmResult) {
                            this.onRefreshGrid();
                        }
                    });
                }
            });
    }

    onFileUpload(dataItem: any, formType: string): void {
        switch (formType) {
            case CommonConstants.leadLineConstants.sizing:
                if (dataItem?.model) {
                    this.onShowUploadSizingDocumentForm(dataItem);
                } else {
                    this.customToastrService.error(CommonConstants.appConstants.modelFieldRequiredText);
                }
                break;
            case CommonConstants.leadLineConstants.drawing:
                this.onShowUploadDrawingDocumentForm(dataItem);
                break;
            case CommonConstants.leadLineConstants.pricing:
                this.onShowUploadDocumentForm(dataItem, CommonConstants.leadLineConstants.pricing);
                break;
            default:
                break;
        }
    }

    onShowUploadSizingDocumentForm(dataItem: any): void {
        this.matDialog.open(SizingDocumentUploadFormComponent, {
            position: { right: '0' },
            panelClass: 'sm-dialog-wrapper',
            hasBackdrop: false,
            data: dataItem || null
        }).afterClosed().subscribe((result: { confirmResult: boolean }) => {
            if (result?.confirmResult) {
                this.onRefreshGrid();
            }
        });
    }

    onShowUploadDrawingDocumentForm(dataItem: any): void {
        this.matDialog.open(DrawingDocumentUploadFormComponent, {
            position: { right: '0' },
            panelClass: 'sm-dialog-wrapper',
            hasBackdrop: false,
            data: dataItem || null
        }).afterClosed().subscribe((result: { confirmResult: boolean }) => {
            if (result?.confirmResult) {
                this.onRefreshGrid();
            }
        });
    }

    onShowConformationRequest(dataItem: any, requestType: number): void {
        this.commonMethod.openConformationDialog(dataItem?.doc_req_id, requestType);
    }

    onShowCancelForm(dataItem: any): void {
        this.selectedDocumentRequestId = dataItem?.doc_req_id;
        this.commonMethod.openDeleteConformationDialog(CommonConstants.appConstants.cancelRequestText);
    }

    onShowReviseDueDateForm(dataItem: any): void {
        this.commonMethod.openReviseDueDateDialog(dataItem?.doc_req_id, CommonConstants.gridNameConstants.documentStatusGrid);
    }

    onShowReassignUserForm(dataItem: any): void {
        this.commonMethod.openReassignFormDialog(dataItem, CommonConstants.gridNameConstants.documentStatusGrid);
    }

    onShowUploadDocumentForm(dataItem: any, formType: string): void {
        this.commonMethod.openUploadDocumentFormDialog(dataItem, formType);
    }

    onCancelRequest(): void {
        if (this.selectedDocumentRequestId) {
            this.kendogridHelper.showLoadingIndicator();
            this.commonService.postChancelRequest(this.selectedDocumentRequestId)
                .pipe(finalize(() => this.kendogridHelper.hideLoadingIndicator()))
                .subscribe(response => {
                    this.selectedDocumentRequestId = '';
                    this.customToastrService.success(response?.message);
                    this.onRefreshGrid();
                });
        }
    }

    onEditField(dataItem: any): void {
        dataItem['isEdit'] = true;
    }

    onCancelEdit(dataItem: any): void {
        dataItem['isEdit'] = false;
    }

    onShowCommentForm(dataItem: any): void {
        const dialogRef = this.matDialog.open(AddCommentFormComponent, {
            panelClass: 'confirm-dialog-wrapper',
            hasBackdrop: false,
            data: {
                requestId: dataItem?.doc_req_id,
                activeUserSourceList: this.activeUserSourceList
            }
        });
        dialogRef.afterClosed().subscribe((result: { confirmResult: boolean }) => {
            if (result?.confirmResult) {
                this.commonMethod.sendLoadAllCommentsValue(this.paramRequestId);
            }
        });
    }

    onCurrencyField(dataItem: any, type: boolean): void {
        if (dataItem.price) {
            dataItem['price'] = type ?
                this.commonMethod.convertCurrencyToDecimal(dataItem.price) : this.commonMethod.convertCurrency(dataItem.price);
        } else {
            dataItem['price'] = '$0.00';
        }
    }

    onUpdatePrice(dataItem: any): void {
        this.kendogridHelper.showLoadingIndicator();
        this.commonService.postUpdatePrice(dataItem.doc_req_id, { price: this.commonMethod.convertCurrencyToDecimal(dataItem.price) })
            .subscribe(response => {
                this.customToastrService.success(response?.message);
                this.onRefreshGrid();
            });
    }

    onSetEditTooltip() {
        let toolTip = '';
        switch (this.formType) {
            case CommonConstants.leadLineConstants.sizing:
                toolTip = 'Edit the model number & sizing document';
                break;
            case CommonConstants.leadLineConstants.drawing:
                toolTip = 'Edit the model number & drawing document';
                break;
            case CommonConstants.leadLineConstants.pricing:
                toolTip = 'Edit the price & pricing document';
                break;
            default:
                toolTip = 'Edit';
                break;
        }
        return toolTip;
    }

    onLoadCommentsById(dataItem: any): void {
        this.selectedIndexValue = 0;
        this.isDocumentWrapperExpand = true;
        this.commonMethod.sendLoadAllCommentsValue(dataItem?.doc_req_id);
    }

    onTabChange(index: number): void {
        this.selectedIndexValue = index;
        this.isDocumentWrapperExpand = true;
        this.commonMethod.sendLoadAllCommentsValue('');
        if (index === 1 || index === 0) {
            this.commonMethod.sendLoadAllCommentsValue(this.paramRequestId);
        }
    }

    onActiveTab(index: number) {
        return this.selectedIndexValue === index ? true : false;
    }

    onToggleExpand(): void {
        this.isDocumentWrapperExpand = !this.isDocumentWrapperExpand;
    }

    onSetTargetLink(fileUrl: string) {
        return this.commonMethod.setTargetLink(fileUrl);
    }

    onSetFileNavigationLink(fileUrl: string) {
        return this.commonMethod.setFileNavigationLink(fileUrl);
    }

    onShowTooltip(e: MouseEvent): void {
        const element = e.target as HTMLElement;
        if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
            && element.offsetWidth < element.scrollWidth) {
            this.tooltipDir.toggle(element);
        } else {
            this.tooltipDir.hide();
        }
    }

    onUpdatUserPermission(): void {
        const userId = this.commonMethod.getUserId();
        this.commonService.getUserFormRecord(userId).subscribe(response => {
            if (response.data) {
                const userData = this.commonMethod.getLoggedUserData();
                userData.user_permission = response.data?.user_permission;
                this.commonMethod.setLoggedUserData(userData);
            }
        });
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}
