<h1 mat-dialog-title>
    <span>Leadline Form Validation</span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="dialog-content-wrapper leadline-form-wrapper h-auto row mb-2">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <p class="mb-0">
        Please fill in all the mandatory fields to create the new {{formTitle}} request!
    </p>
    <div *ngFor="let item of leadline_mandatory_check?.leadline_sections" class="col-6 mt-2 pt-2">
        <li class="field-wrapper" [ngClass]="(item?.is_completed ? '':'error-content')"
            (click)="onNavigateLeadLine(item)" role="button">
            <img src="assets/images/{{onSetImage(item?.is_completed)}}.svg" />
            <span>
                {{item?.form_name}}
            </span>
        </li>
    </div>
</div>