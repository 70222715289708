<div class="details-box-wrapper">
    <div class="row mb-1 lead-edit-summary-status">
        <div class="col-8 ps-0">
            <div class="status-title">Status</div>
        </div>
        <div class="col-4 pe-0" *ngIf="isLeadDetailsRoute()">
            <span class="button-wrapper float-end">
                <span class="icon-button-wrapper" matTooltip="Edit" (click)="onShowEditStatusInformation()">
                    <img src="assets/images/revenue-icon.svg" height="14" />
                </span>
            </span>
        </div>
    </div>
    <div class="text-wrapper">
        <div class="row">
            <div class="col-lg-6">
                <div class="text-content">
                    <ng-container [ngSwitch]="leadDetailsModel?.status">
                        <span *ngSwitchCase="'Open'" class="status-btn error-color">Open</span>
                        <span *ngSwitchCase="'Pending'" class="status-btn warning-color">Pending</span>
                        <span *ngSwitchCase="'Closed'" class="status-btn success-color">Closed</span>
                        <span *ngSwitchDefault>-</span>
                    </ng-container>
                    <div class="label mt-2">Status</div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.deal_stage_date | valueCheck}}</div>
                    <div class="label">Lead Stage Date</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.lead_date | valueCheck}}</div>
                    <div class="label">Lead Date</div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="text-content">
                    <ng-container [ngSwitch]="leadDetailsModel?.deal_stage">
                        <span *ngSwitchCase="'UL'">Unqualified Lead</span>
                        <span *ngSwitchCase="'PREL'">Preliminary Design</span>
                        <span *ngSwitchCase="'DD'">Detailed Design</span>
                        <span *ngSwitchCase="'BID'">Bidding</span>
                        <span *ngSwitchCase="'ABR'">Awaiting Bid Results</span>
                        <span *ngSwitchCase="'WIN'">Win</span>
                        <span *ngSwitchCase="'LOST'">Bid Lost to Competitor</span>
                        <span *ngSwitchCase="'DEAD'">No Bid Project Dead</span>
                        <span *ngSwitchDefault>-</span>
                    </ng-container>
                    <div class="label">Lead Stage</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.closed_date | valueCheck}}</div>
                    <div class="label">Closed Date</div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.closed_by | valueCheck}}</div>
                    <div class="label">Closed By</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.closed_reason | valueCheck}}</div>
                    <div class="label">Closed Reason</div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.bid_date | valueCheck}}</div>
                    <div class="label">Bid Date</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.expected_close_date | valueCheck}}</div>
                    <div class="label">Expected Close Date</div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.startup_date | valueCheck}}</div>
                    <div class="label">Startup Date</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.startup_tech | valueCheck}}</div>
                    <div class="label">Startup Tech</div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="text-content">
                    <div class="text">{{leadDetailsModel?.serial_numbers | valueCheck}}</div>
                    <div class="label">Serial Numbers</div>
                </div>
            </div>
        </div>
    </div>
</div>