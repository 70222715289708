import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpCommonService } from './http-common.service';

@Injectable({
    providedIn: 'root'
})

export class ProfileService {

    constructor(
        private httpCommonService: HttpCommonService) {
    }

    postUserFormSettings(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord('user_setting', inputData);
    }

    postAddUserForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord('users', inputData);
    }

    postUpdateUserForm(userId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`users/${userId}`, inputData);
    }

    deleteUserRecord(userId: string): Observable<any> {
        return this.httpCommonService.deleteRecord(`users/${userId}`, {});
    }

    getUserRoles(): Observable<any> {
        return this.httpCommonService.getRecord('users/roles');
    }

    getUserGroup(): Observable<any> {
        return this.httpCommonService.getRecord('users/groups');
    }

    getUserNotifications(): Observable<any> {
        return this.httpCommonService.getRecord('users/notifications');
    }

    postSearchForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord('report/managers', inputData);
    }

    postUsersGridRecords(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`user/grid`, inputData);
    }

    postUserUpdateForm(userId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`users/update/${userId}`, inputData);
    }

    postChangePasswordForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord('user/changepassword', inputData);
    }

    getReportFields(): Observable<any> {
        return this.httpCommonService.getRecord('lead-report-fields');
    }

    postReportGridRecords(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord('lead-report', inputData);
    }

    exportReportGridRecords(inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord('lead-report-export', inputData);
    }

    postScheduleReportForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord('save-schedule-report', inputData);
    }

    getScheduledReports(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord('get-schedule-report', inputData);
    }

    deleteScheduledReport(inputData: object): Observable<any> {
        return this.httpCommonService.deleteRecord('delete-schedule-report', inputData)
    }

    updateScheduledReport(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`update-schedule-report`, inputData);
    }

    postServiceReportGridRecords(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord('service-report', inputData);
    }

    getServiceReportFields(): Observable<any> {
        return this.httpCommonService.getRecord('service-report-fields');
    }

    // Log History

    getUserLoginCount(): Observable<any> {
        return this.httpCommonService.postRecord('get-user-lgn-count', {});
    }

    getUserLoginDropdown(): Observable<any> {
        return this.httpCommonService.getRecord('get-user-lgn-dropdown');
    }

    getUserLoginGridRecords(inputData: any): Observable<any> {
        return this.httpCommonService.postRecord('get-user-lgn-grid', inputData);
    }

}
