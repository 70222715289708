import * as ngCommon from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Material Modules

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

// End

// Kendo Modules

import { ChartsModule } from '@progress/kendo-angular-charts';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipModule, TooltipsModule } from '@progress/kendo-angular-tooltip';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { EditorModule as KendoEditorModule } from "@progress/kendo-angular-editor";

// End

// NG Modules

import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { MentionModule } from 'angular-mentions';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InfiniteScrollModule } from "ngx-infinite-scroll";

// End

import 'hammerjs';
import { ToastrModule } from 'ngx-toastr';
import { UtilsModule } from 'src/app-core/utils.module';
import { AddCommentFormComponent } from './add-comment-form/add-comment-form.component';
import { AllCommentsListComponent } from './all-comments-list/all-comments-list.component';
import { AllDocumentsListComponent } from './all-documents-list/all-documents-list.component';
import { AllLogsListComponent } from './all-logs-list/all-logs-list.component';
import { AllThreadListComponent } from './all-thread-list/all-thread-list.component';
import { ConformationDialogComponent } from './conformation-dialog/conformation-dialog.component';
import { DeleteConformationDialogComponent } from './delete-conformation-dialog/delete-conformation-dialog.component';
import { DocumentStatusListComponent } from './document-status-list/document-status-list.component';
import { DrawingDocumentUploadFormComponent } from './drawing-document-upload-form/drawing-document-upload-form.component';
import { GridFilterDropdownComponent } from './grid-filter-dropdown/grid-filter-dropdown.component';
import { GridFilterFormComponent } from './grid-filter-form/grid-filter-form.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { IssueFormComponent } from './issue-form/issue-form.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { CompanyFormComponent } from './company-form/company-form.component';
import { LeadDetailsInfoComponent } from './lead-details-info/lead-details-info.component';
import { LeadOrganizationInfoComponent } from './lead-organization-info/lead-organization-info.component';
import { LeadRecentActivityInfoComponent } from './lead-recent-activity-info/lead-recent-activity-info.component';
import { LeadStatusInfoComponent } from './lead-status-info/lead-status-info.component';
import { LeadSummaryComponent } from './lead-summary/lead-summary.component';
import { LeadlineRevenueEditorDialogComponent } from './leadline-revenue-editor-dialog/leadline-revenue-editor-dialog.component';
import { LoaderIndicatorComponent } from './loader-indicator/loader-indicator.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ReassignUserFormComponent } from './reassign-user-form/reassign-user-form.component';
import { RequestFormComponent } from './request-form/request-form.component';
import { ResolveIssueFormComponent } from './resolve-issue-form/resolve-issue-form.component';
import { ReviseDueDateDialogComponent } from './revise-due-date-dialog/revise-due-date-dialog.component';
import { SizingDocumentUploadFormComponent } from './sizing-document-upload-form/sizing-document-upload-form.component';
import { TinyEditorComponent } from './tiny-editor/tiny-editor.component';
import { UploadDocumentDialogComponent } from './upload-document-dialog/upload-document-dialog.component';
import { CreateLeadFormComponent } from './create-lead-form/create-lead-form.component';
import { EditLeadFormComponent } from './lead-form/edit-lead-form.component';
import { LeadlineFormCheckDialogComponent } from './leadline-form-check-dialog/leadline-form-check-dialog.component';

@NgModule({
    imports: [
        ngCommon.CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        UtilsModule,
        LabelModule,
        InputsModule,
        DateInputsModule,
        DropDownsModule,
        GridModule,
        PDFModule,
        PopupModule,
        TreeViewModule,
        TooltipsModule,
        PdfViewerModule,
        InfiniteScrollModule,
        MentionModule,
        EditorModule,
        KendoEditorModule,
        ChartsModule,
        MatMenuModule,
        MatInputModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatRadioModule,
        MatDialogModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatBadgeModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatTabsModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatIconModule,
        MatChipsModule,
        MatSidenavModule,
        MatPaginatorModule,
        MatRippleModule,
        ExcelModule,
        TooltipModule,
        ToastrModule.forRoot({
            closeButton: true,
            progressBar: false,
            progressAnimation: 'increasing',
            positionClass: 'toast-top-right',
            timeOut: 3000,
            preventDuplicates: true
        }),
    ],
    declarations: [
        AddCommentFormComponent,
        AllCommentsListComponent,
        AllDocumentsListComponent,
        AllLogsListComponent,
        AllThreadListComponent,
        ConformationDialogComponent,
        DeleteConformationDialogComponent,
        DocumentStatusListComponent,
        DrawingDocumentUploadFormComponent,
        GridFilterFormComponent,
        GridFilterDropdownComponent,
        HeaderBarComponent,
        IssueFormComponent,
        ContactFormComponent,
        CompanyFormComponent,
        LeadDetailsInfoComponent,
        LeadOrganizationInfoComponent,
        LeadRecentActivityInfoComponent,
        LeadStatusInfoComponent,
        LeadSummaryComponent,
        LoaderIndicatorComponent,
        PageNotFoundComponent,
        ReassignUserFormComponent,
        RequestFormComponent,
        ResolveIssueFormComponent,
        ReviseDueDateDialogComponent,
        SizingDocumentUploadFormComponent,
        TinyEditorComponent,
        UploadDocumentDialogComponent,
        LeadlineRevenueEditorDialogComponent,
        CreateLeadFormComponent,
        EditLeadFormComponent,
        LeadlineFormCheckDialogComponent
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        LabelModule,
        InputsModule,
        DateInputsModule,
        DropDownsModule,
        GridModule,
        PDFModule,
        ExcelModule,
        PopupModule,
        TreeViewModule,
        TooltipsModule,
        PdfViewerModule,
        InfiniteScrollModule,
        MentionModule,
        EditorModule,
        KendoEditorModule,
        ChartsModule,
        UtilsModule,
        MatMenuModule,
        MatInputModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatRadioModule,
        MatDialogModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatBadgeModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatTabsModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatIconModule,
        MatChipsModule,
        MatSidenavModule,
        MatPaginatorModule,
        MatRippleModule,
        AddCommentFormComponent,
        AllCommentsListComponent,
        AllDocumentsListComponent,
        AllLogsListComponent,
        AllThreadListComponent,
        ConformationDialogComponent,
        DeleteConformationDialogComponent,
        DocumentStatusListComponent,
        DrawingDocumentUploadFormComponent,
        GridFilterFormComponent,
        GridFilterDropdownComponent,
        HeaderBarComponent,
        IssueFormComponent,
        ContactFormComponent,
        CompanyFormComponent,
        LeadDetailsInfoComponent,
        LeadOrganizationInfoComponent,
        LeadRecentActivityInfoComponent,
        LeadStatusInfoComponent,
        LeadSummaryComponent,
        LoaderIndicatorComponent,
        PageNotFoundComponent,
        ReassignUserFormComponent,
        RequestFormComponent,
        ResolveIssueFormComponent,
        ReviseDueDateDialogComponent,
        TinyEditorComponent,
        UploadDocumentDialogComponent,
        TooltipModule,
        LeadlineRevenueEditorDialogComponent,
        CreateLeadFormComponent,
        EditLeadFormComponent
    ],
    providers: [
        ngCommon.DatePipe,
    ],
    entryComponents: [
        AddCommentFormComponent,
        ConformationDialogComponent,
        DeleteConformationDialogComponent,
        DrawingDocumentUploadFormComponent,
        GridFilterFormComponent,
        IssueFormComponent,
        ContactFormComponent,
        CompanyFormComponent,
        ReassignUserFormComponent,
        RequestFormComponent,
        ResolveIssueFormComponent,
        ReviseDueDateDialogComponent,
        SizingDocumentUploadFormComponent,
        LeadlineRevenueEditorDialogComponent,
        CreateLeadFormComponent,
        EditLeadFormComponent,
        LeadlineFormCheckDialogComponent
    ]
})
export class AppCommonModule {
    static forRoot() {
        return {
            ngModule: AppCommonModule,
            providers: [
                { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
            ]
        };
    }
}
