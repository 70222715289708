<div class="lead-wrapper">
    <div class="page-header-wrapper">
        <div class="row">
            <div class="col-2 p-0 d-flex align-items-center">
                <a class="display-6 text-underline-hover" [routerLink]="['/dashboard']" role="button">
                    Leads
                </a>
                <img src="assets/images/arrow-right-icon.svg" class="ms-1 me-1" />
                <span class="display-6 black-color-text">ID : </span>
                <a class="display-6 text-underline-hover ps-2" role="button"
                    routerLink="/lead-details/lead/{{this.paramLeadId}}/0">
                    {{paramLeadId}}
                </a>
                <img src="assets/images/arrow-right-icon.svg" class="ms-1 me-1" />
                <span class="display-6">
                    <span class="black-color-text">Leadline :</span>
                    <span class="ps-2">{{paramLeadLineId}}</span>
                </span>
            </div>
            <div class="col-10 p-0 d-flex align-items-center justify-content-end">
                <span class="white-box-wrapper">
                    <span class="text">Lead ID</span>
                    <span class="label">{{paramLeadId}}</span>
                </span>
                <span class="white-box-wrapper">
                    <span class="text">LeadLine</span>
                    <span class="label-light">
                        {{leadDetailsHeaderModel.leadline_id}}
                        {{leadDetailsHeaderModel.part_id ? ' - ' + leadDetailsHeaderModel.part_id : ''}}
                        {{leadDetailsHeaderModel.quantity ? ' - ' + leadDetailsHeaderModel.quantity : ''}}
                    </span>
                </span>
                <span class="white-box-wrapper">
                    <span class="text">Projected Revenue</span>
                    <span class="label-light">
                        {{leadDetailsHeaderModel.projected_revenue | currencyValue}}
                    </span>
                </span>
                <span class="progressbar-wrapper">
                    <span class="progress-content"
                        [style.width.%]="leadDetailsHeaderModel.score ? (leadDetailsHeaderModel.score | number: '1.0-0') : '1'">
                        <span class="ps-3" [ngClass]="{'text-color-black': leadDetailsHeaderModel.score <= 15}">
                            {{leadDetailsHeaderModel.score ? (leadDetailsHeaderModel.score | number: '1.0-0') : '0'}}%
                        </span>
                    </span>
                </span>
            </div>
        </div>
    </div>
    <div class="page-content-wrapper" [ngClass]="{'document-expansion-wrapper': onCheckCurrentRoute()}">
        <div class="left-section">
            <ul>
                <li *ngFor="let item of leadSideNavBarModel">
                    <a class="nav-link"
                        [ngClass]="{'success-content': item?.isCompleted ? true: false}"
                        [routerLink]="item.route" routerLinkActive="active" matRipple (click)="onValidateNavigation()">
                        <img src="assets/images/check-circle-icon.svg" *ngIf="item.isIcon" />
                        <span>{{item.label}}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="right-section">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>